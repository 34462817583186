<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn class="mr-3" elevation="1" fab small @click="setDrawer(!drawer)">
      <v-icon v-if="value"> mdi-view-quilt </v-icon>

      <v-icon v-else> mdi-dots-vertical </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="this.capitalize($route.name.replace('_', ' '))"
    />

    <v-spacer />
    <!--
    <v-text-field
      :label="$t('search')"
      color="secondary"
      hide-details
      style="max-width: 165px;"
    >
      <template
        v-if="$vuetify.breakpoint.mdAndUp"
        v-slot:append-outer
      >
        <v-btn
          class="mt-n2"
          elevation="1"
          fab
          small
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </template>
    </v-text-field>
-->
    <div class="mx-3" />
    <!--
    <v-btn
      class="ml-2"
      min-width="0"
      text
      to="/"
    >
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>
-->
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-badge v-if="notifications.length > 0" color="red" overlap bordered>
            <template v-slot:badge>
              <span>{{ notifications.length - 1 }}</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
          <v-badge v-else overlap bordered>
            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list :tile="false" nav>
        <div v-if="notifications.length > 0">
          <app-bar-item v-for="(n, i) in notifications" :key="`item-${i}`">
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
        <div v-else>
          <app-bar-item>
            <v-list-item-title>No hay notificaciones</v-list-item-title>
          </app-bar-item>
        </div>
      </v-list>
    </v-menu>
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>
      <v-list :tile="false" nav dense>
        <div v-if="items.length > 0">
          <app-bar-item v-for="(n, i) in items" :key="`item-${i}`">
            <v-list-item v-text="n.text" v-bind:to="n.link" />
          </app-bar-item>
          <v-divider></v-divider>
          <app-bar-item>
            <v-list-item href="#" @click="logout()">Salir</v-list-item>
          </app-bar-item>
        </div>
        <div v-else>
          <v-list-item href="#" @click="logout()">Salir</v-list-item>
        </div>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import axios from "axios";
// Components
import { VHover, VListItem } from "vuetify/lib";

// Utilities
import { mapState, mapMutations } from "vuex";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "DashboardCoreAppBar",

  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => {
              return h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              );
            },
          },
        });
      },
    },
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    notifications: [],
    items: [
      /*{ text: 'Profile', icon: 'mdi-clock', link: '/pages/user' },*/
    ],
    intervalId: 0,
  }),

  computed: {
    ...mapState(["drawer"]),
    ...mapGetters({
      isLogged: "authenticated",
    }),
  },
  created() {
    console.log(this.$keycloak.tokenParsed);
    /*if(this.isLogged){
      this.getAlertas();
    }*/
  },
  mounted: function () {
    this.intervalId = window.setInterval(() => {
      //console.log('esta logueado: '+this.isLogged);
      if (this.isLogged == true) {
        /*var today = new Date();
          var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
          var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
          var dateTime = date+' '+time;*/
        //console.log('consultando alertas: '+dateTime);
        //this.getAlertas();
      } else {
        window.clearInterval(this.intervalId);
      }
    }, 600000); //10 minutos
    //}, 300000) //5 minutos
    //}, 10000)
  },
  methods: {
    /*
    getAlertas(){
      axios.get('/api/notificaciones',)
      .then(r => {
          this.notifications = r.data;                
      })
      .catch(function(error){
          this.snackbar = true
          this.text = 'Error al obtener solicitudes. Error: '+ error;
          this.color = 'error'; 
      });
    },
    */
    ...mapActions({
      signOut: "logout",
    }),
    async logout() {
      var logoutOptions = { redirectUri: "" };
      this.$keycloak
        .logout(logoutOptions)
        .then((success) => {
          console.log("--> log: logout success ", success);
        })
        .catch((error) => {
          console.log("--> log: logout error ", error);
        });
      store.commit("logout");
    },
    /*logout(){
        axios.post('/logout').then(() => {
            sessionStorage.clear();
            this.$router.push({ name: "home" });
        }).catch(error => {
          this.formData.error = error.response.data;
        });
      },*/
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    submit: function () {
      this.$refs.form.submit();
    },
    capitalize(s) {
      if (typeof s !== "string") return "";
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
  },
};
</script>
